import React, { useEffect } from "react";
import Img from "gatsby-image";
import BgImage from "gatsby-background-image";
import { Row, Col } from "react-bootstrap";
import SEO from "@components/SEO";
import { ContactProps } from "src/types";

import styles from "./contact.module.scss";

export default function Contact(props: ContactProps) {
  const { btnWA, bgDesktop, bgMobile } = props.data;

  useEffect(() => {
    const isDevEnv = process.env.NODE_ENV === "development";

    // Track conversion
    if (typeof window !== "undefined" && !isDevEnv) {
      window.gtag("event", "conversion", {
        "send_to": "AW-982362450/a2AeCPa72ZYBENLSttQD",
      });
    }

    // Track Initial Checkout Facebook Pixel
    if (typeof window?.fbq === "function" && !isDevEnv) {
      window.fbq("track", "InitiateCheckout");
    }
  }, []);

  // Art directing background
  const bg = [
    bgMobile.childImageSharp.fluid,
    {
      ...bgDesktop.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  return (
    <BgImage Tag="section" fluid={bg} className={styles.contact}>
      <SEO title="Terima kasih!" />
      <div className={styles.container}>
        <Row className="h-100 justify-content-between">
          <Col lg={5} className={styles.contactInfo}>
            <div className={styles.contactInfoInner}>
              <p className={styles.text1}>
                Silahkan klik tombol hijau di bawah ini untuk langsung chat
                dengan CS kami melalui WhatsApp
              </p>
              <div
                className={styles.btnWa}
                onClick={() => {
                  window.open("https://nanya.online/gudang-seragam", "_blank");
                }}
              >
                <Img
                  fluid={btnWA.childImageSharp.fluid}
                  alt="whatsapp"
                  imgStyle={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <p className={styles.text2}>
                Jika loading tombol di atas mengalami masalah atau loadingnya
                terlalu lama, silahkan menghubungi kami melalui nomor di bawah
                ini
              </p>
              <div className={styles.contactList}>
                <p className={styles.phone}>(Devi) 0813-1420-3335</p>
                <p className={styles.phone}>(Diana) 0859-2226-7775</p>
                <p className={styles.phone}>(021) 775-7089</p>
              </div>
            </div>
          </Col>
          <Col lg={7} className={styles.mapContainer}>
            <p>Klik peta di bawah ini untuk melihat petunjuk arah:</p>
            <iframe
              title="Gudang Seragam - Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.961470099413!2d106.8135499148554!3d-6.398966695369942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e95f083f99fd%3A0xf75e552abbcc1785!2sGudang%20seragam!5e0!3m2!1sid!2sid!4v1600052427880!5m2!1sid!2sid"
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              style={{ border: 0 }}
              allowFullScreen
              className={styles.map}
            ></iframe>
          </Col>
        </Row>
      </div>
    </BgImage>
  );
}
